import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useOrgSlug } from '~/hooks/usePersistedOrgSlug';
import { usePersonalizedLandingPageExperiment } from '~/engagement-experiments/personalizedLandingPage';

export const PipelinesOrProjects = () => {
  const orgSlug = useOrgSlug();
  const router = useRouter();

  const { loading, variationName } = usePersonalizedLandingPageExperiment();
  useEffect(() => {
    const queryParams = window.location.search;
    if (queryParams) {
      if (router.query['intent'] === 'purchase-performance-plan' && orgSlug) {
        window.location.replace(
          `/settings/plan/${orgSlug}/overview${queryParams}`,
        );
      } else {
        window.location.replace(`/projects${queryParams}`);
      }
    } else if (orgSlug) {
      if (!loading) {
        if (variationName === 'treatment') {
          window.location.replace(`/insights/${orgSlug}`);
        } else {
          window.location.replace(`/pipelines/${orgSlug}`);
        }
      }
    } else {
      window.location.replace('/projects');
    }
  });

  return null;
};

PipelinesOrProjects.getInitialProps = async ({ res }) => {
  res?.setHeader('Cache-Control', 'public, max-age=600');

  return {};
};
