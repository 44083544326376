import { useGrowthVariationNameByUserId } from '@circleci/react-experiments';
import useUserRole from '~/engagement-experiments/personalizedLandingPage/hooks/useUserRole';

export const experimentName = 'personalized_landing_page_by_role_v2_test';
const groupExperimentName = 'q1_fy23_engagement_experiment_user_group_test';

const usePersonalizedLandingPageExperiment = () => {
  const { loading: loadingUserRole, userRole } = useUserRole();

  const {
    loading: loadingVariationName,
    variationName,
  } = useGrowthVariationNameByUserId({
    experimentName,
    groupExperimentName,
    isQualified:
      userRole === 'Engineering Manager' || userRole === 'DevOps Manager',
  });

  return {
    loading: loadingVariationName || loadingUserRole,
    variationName,
  };
};

export default usePersonalizedLandingPageExperiment;
